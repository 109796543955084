import React from "react";
import img1 from "./image/New Website Blue Mockup Instagram - Laptop.mp4";

const onlineIcons = [
  "https://img.icons8.com/color/48/000000/graduation-cap.png",
  "https://img.icons8.com/color/48/000000/open-book.png",
  "https://img.icons8.com/color/48/000000/idea.png",
  "https://img.icons8.com/color/48/000000/certificate.png",
];

const UniversityBanner = () => {
  return (
    <div className="w-full flex justify-center bg-gradient-to-br from-blue-50 to-blue-100 relative overflow-hidden">
      <div className="md:w-[98%] w-full relative flex flex-col lg:flex-row items-center justify-between px-6 py-2 lg:py-20 space-y-12 lg:space-y-0">
        {/* Left Section */}
        <div className="w-full lg:w-1/2 text-left space-y-6">
          <h1 className="text-2xl md:text-4xl lg:text-5xl font-bold text-gray-800 leading-tight">
            Discover Excellence at{" "}
            <span className="text-orange-500">Parul University</span>
          </h1>
          <p className="text-sm md:text-lg text-gray-600">
            Explore top programs tailored to shape your future with innovative
            learning experiences.
          </p>

          {/* Courses Section */}
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-6 hidden md:flex">
            {["BCA", "MCA", "MBA", "B.Tech"].map((course, index) => (
              <div
                key={index}
                className="p-4 bg-white flex flex-col items-center text-center space-y-4 transform transition duration-300"
              >
                <h4 className="text-sm md:text-xl font-semibold text-gray-800">
                  {course}
                </h4>
                <p className="text-xs md:text-sm text-gray-600">
                  Duration: {course === "B.Tech" ? "4 Years" : "2 Years"}
                </p>
                <button className="mt-4 bg-orange-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-orange-600 transition duration-300">
                  Apply Now
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Right Section */}
        <div className=" md:w-[40%] flex w-full">
          <div className="lg:bottom-0 right-0 w-[100%] md:w-[60%] bg-white md:p-6 p-2 transform transition duration-300 hover:scale-105 space-y-4">
            <h3 className="text-lg md:text-2xl font-semibold text-gray-800 text-center">
              Get in Touch
            </h3>
            <form className="space-y-4">
              <input
                type="text"
                placeholder="Your Name"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 text-[12px] md:text-base"
              />
              <input
                type="email"
                placeholder="Your Email"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 text-[12px] md:text-base"
              />
              <select
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 text-[12px] md:text-base"
                defaultValue=""
              >
                <option value="" disabled>
                  Select a Program
                </option>
                <option>BCA</option>
                <option>MCA</option>
                <option>MBA</option>
                <option>B.Tech</option>
              </select>
              <button
                type="submit"
                className="w-full bg-orange-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-orange-600 transition duration-300 text-sm md:text-base"
              >
                Submit
              </button>
            </form>
          </div>

          <div className="w-full md:w-[80%] h-full flex items-center justify-center relative ">
            {/* Video */}
            <video
              src={img1}
              alt="Parul University"
              className="w-[100%] md:w-[100%] h-full object-cover transform transition duration-300 hover:scale-105"
              autoPlay
              muted
              loop
            />
          </div>
        </div>
      </div>

      {/* Floating Online Icons */}
      <div className="absolute top-[5%] w-full flex justify-around px-4 md:px-12 hidden md:flex">
        {onlineIcons.map((icon, index) => (
          <img
            key={index}
            src={icon}
            alt={`Flat Icon ${index + 1}`}
            className="w-10 md:w-12 h-10 md:h-12"
          />
        ))}
      </div>
    </div>
  );
};

export default UniversityBanner;
