import React from "react";
import "./index.css";

const InfiAnimation = ({ uni }) => {
  return (
    <div className="slider-container">
      <div className="slider">
        <div className="slide-track">
          {uni &&
            uni.hiringPartner.map((data, index) => (
              <div className="slide px-6 py-3" key={`original-${index}`}>
                <img src={data} width={100} height={100} alt={`Partner ${index}`} />
              </div>
            ))}
          {/* Duplicate the icons for seamless looping */}
          {uni &&
            uni.hiringPartner.map((data, index) => (
              <div className="slide px-6 py-3" key={`duplicate-${index}`}>
                <img src={data} width={100} height={100} alt={`Partner Duplicate ${index}`} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default InfiAnimation;
