import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "./index.css";

// Import required modules
import { FreeMode, Navigation, Autoplay } from "swiper/modules";

const NewsSlider = ({ uni }) => {
  return (
    <div className="md:pt-[4rem]  pt-2 md:pb-[5rem] pb-1 px-2 bg-white">
      <h1 className="text-gray-800 font-bold md:text-2xl text-[16px] md:ml-[5rem] ml-[3.5rem]">
        Our Students' Success Stories
      </h1>
      <p className="text-gray-600 md:text-sm text-[12px] md:ml-[5rem] ml-[3.5rem] mt-2">
        Watch inspiring success stories of our students who excelled in their fields.
      </p>
      <div className="w-full flex justify-center pb-[30px] pt-[25px]  overflow-hidden">
        <div className="w-[98%]">
          <Swiper
            slidesPerView={5}
            spaceBetween={30}
            freeMode={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 40,
              },
            }}
            navigation={false}
            modules={[FreeMode, Navigation, Autoplay]}
            className="mySwiper"
          >
            {uni &&
              uni.successStory.map((data, index) => (
                <SwiperSlide key={index}>
                  <div className="w-full md:h-[14rem] h-[8rem] bg-gray-100 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition duration-300">
                    <iframe
                      width="100%"
                      height="100%"
                      src={data}
                      title={`Student Story ${index + 1}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen
                      className="rounded-t-lg"
                    ></iframe>
                    <div className="p-3 text-center bg-gray-50">
                      <p className="text-sm font-medium text-gray-700">
                        Success Story {index + 1}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default NewsSlider;
