import React from "react";
import { Link } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import image1 from "./s-blogimg-01.png";
import image2 from "./s-blogimg-02.png";

function Footer({ uni }) {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="w-full bg-blue-50 text-black">
        {/* Main Footer Section */}
        <div className="container mx-auto py-10 px-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* About Us */}
          <div>
            <h4 className="text-orange-500 text-[16px] md:text-[20px] font-bold mb-4 border-b-2 border-orange-500 inline-block">
              About Us
            </h4>
            <p className="text-gray-400 text-[12px]">
              Parul University supports its students to succeed at every level
              to become well-rounded individuals and skilled professionals. The
              Trust runs six colleges–Unitedworld Institute of Design,
              Unitedworld School of Business, Unitedworld School of Liberal
              Arts & Mass Communication, and more.
            </p>
            <div className="flex gap-4 mt-4">
              <FaFacebookF className="w-8 h-8 p-2 rounded-full bg-gray-600 hover:bg-orange-500" />
              <FaInstagram className="w-8 h-8 p-2 rounded-full bg-gray-600 hover:bg-orange-500" />
              <FaTwitter className="w-8 h-8 p-2 rounded-full bg-gray-600 hover:bg-orange-500" />
            </div>
          </div>

          {/* Latest Post */}
          <div>
            <h4 className="text-orange-500 text-[16px] md:text-[20px] font-bold mb-4 border-b-2 border-orange-500 inline-block">
              Latest Posts
            </h4>
            <Link to="/blogpost1" onClick={handleClick} className="flex items-center mb-4">
              <img src={image1} alt="Latest Post" className="w-16 h-16 object-cover rounded-lg" />
              <div className="ml-4">
                <p className="text-[12px] font-medium hover:text-orange-500">
                  Parul University's Journey of Empowering Future Leaders
                </p>
                <span className="text-[10px] text-gray-500">15 March, 2024</span>
              </div>
            </Link>
            <Link to="/blogpost2" onClick={handleClick} className="flex items-center">
              <img src={image2} alt="Latest Post" className="w-16 h-16 object-cover rounded-lg" />
              <div className="ml-4">
                <p className="text-[12px] font-medium hover:text-orange-500">
                  Why Parul University Is the Top Choice for Future Leaders
                </p>
                <span className="text-[10px] text-gray-500">20 March, 2024</span>
              </div>
            </Link>
          </div>

          {/* Quick Links */}
          <div>
            <h4 className="text-orange-500 text-[16px] md:text-[20px] font-bold mb-4 border-b-2 border-orange-500 inline-block">
              Quick Links
            </h4>
            <div className="grid grid-cols-2 gap-2 text-[12px] text-gray-400">
              <a href="#" className="hover:text-orange-500">Parul University</a>
              <a href="#" className="hover:text-orange-500">Parul MBA Fees</a>
              <a href="#" className="hover:text-orange-500">Parul Admissions</a>
              <a href="#" className="hover:text-orange-500">Parul Hostel Fees</a>
              <a href="#" className="hover:text-orange-500">Parul BTech Fees</a>
              <a href="#" className="hover:text-orange-500">Parul Courses</a>
            </div>
          </div>

          {/* Disclaimer */}
          <div>
            <h4 className="text-orange-500 text-[16px] md:text-[20px] font-bold mb-4 border-b-2 border-orange-500 inline-block">
              Disclaimer
            </h4>
            <p className="text-gray-400 text-[12px]">
              The purpose of aaopadhe is to provide accurate and unbiased
              information about universities. The content on the website is for
              informational purposes only and is not intended to replace or
              substitute for any offerings provided by our academia partners.
            </p>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="bg-[#1a1a1a] py-4 text-center">
          <p className="text-[12px] text-gray-500">
            © 2024 Aaopadhe. All Rights Reserved.
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
