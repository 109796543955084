import React from "react";
import "./index.css";
import hostel from "./image/hostel.png";
import cafe from "./image/cafe.png";
import lab from "./image/lab.png";
import computerLab from "./image/lab-technician.png";
import medical from "./image/medical-team.png";
import transport from "./image/shipment.png";
import gym from "./image/weightlifter.png";
import wifi from "./image/wifi.png";
import ci from "./image/cinema.png";
import library from "./image/library (1).png";

const facilities = [
  { img: gym, title: "Gym", bg: "bg-orange-50", text: "text-orange-700" },
  { img: hostel, title: "Hostel", bg: "bg-blue-50", text: "text-blue-700" },
  { img: cafe, title: "Cafeteria", bg: "bg-green-50", text: "text-green-700" },
  { img: lab, title: "Laboratory", bg: "bg-purple-50", text: "text-purple-700" },
  { img: computerLab, title: "Computer Labs", bg: "bg-pink-50", text: "text-pink-700" },
  { img: ci, title: "Auditorium", bg: "bg-yellow-50", text: "text-yellow-700" },
  { img: wifi, title: "Wi-Fi Campus", bg: "bg-teal-50", text: "text-teal-700" },
  { img: transport, title: "Transport", bg: "bg-red-50", text: "text-red-700" },
  { img: medical, title: "Medical", bg: "bg-indigo-50", text: "text-indigo-700" },
  // { img: library, title: "Library", bg: "bg-gray-50", text: "text-gray-700" },
];

const UniversityFacilities = ({ uni }) => {
  return (
    <div className="flex flex-col items-center w-full px-4 md:py-10 py-1 bg-white">
      {/* Section Heading */}
      <h2 className="text-lg md:text-2xl font-semibold text-gray-800 text-center mb-6">
        {uni && uni.university} <span className="text-blue-700">Facilities</span>
      </h2>

      {/* Facilities Grid */}
      <div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-9 md:gap-6 gap-4 w-full md:px-3 px-1">
        {facilities.map((facility, index) => (
          <div
            key={index}
            className={`flex flex-col items-center md:p-4 p-1 rounded-lg shadow-md hover:shadow-lg transform hover:scale-105 transition-transform duration-300 ${facility.bg}`}
          >
            <img
              src={facility.img}
              className="h-6 w-6 md:h-16 md:w-16 mb-3"
              alt={`${facility.title} icon`}
            />
            <h3 className={`text-[12px] md:text-base font-medium ${facility.text}`}>
              {facility.title}
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UniversityFacilities;
