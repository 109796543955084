import React from "react";

const   Achievements = () => {
  const achievements = [
    { title: "50,000+", description: "Students Graduated" },
    { title: "300+", description: "Top Hiring Partners" },
    { title: "50+", description: "National and International Awards" },
    { title: "100%", description: "Placement Assistance" },
  ];

  return (
    <div className="w-full md:py-8 py-2 bg-white">
      <div className="max-w-[98%] mx-auto">
        <h2 className="text-center text-[24px] font-bold mb-2 md:mb-6 text-gray-800">
          Our <span className="text-[#FF0000]">Achievements</span>
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {achievements.map((item, index) => (
            <div
              key={index}
              className="bg-[#f9f9f9] p-6 rounded-lg shadow-lg text-center hover:shadow-xl transition duration-300"
            >
              <h3 className="md:text-[36px] text-[16px] font-bold text-[#FF0000]">
                {item.title}
              </h3>
              <p className="text-gray-600 mt-2 text-[12px] md:text-[20px]">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Achievements;
