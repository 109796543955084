import React, { useState, useEffect } from "react";
import { MdGroups3 } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { FaReact } from "react-icons/fa";
import feature1 from "./image/parul1.webp";
import feature2 from "./image/parul2.webp";
import feature3 from "./image/parul3.webp";

function OurFeatures({ uni }) {
  const [currentSlide, setCurrentSlide] = useState(1);

  // Define images for the slider
  const images = [feature1, feature2, feature3];

  // Automatic slider functionality
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide % images.length) + 1);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [images.length]);

  const features = [
    {
      icon: <MdGroups3 />,
      subHed: "Skilled Teachers",
      preGraph:
        "We take pride in our highly skilled and experienced faculty, ensuring students receive the best education and guidance.",
    },
    {
      icon: <GiReceiveMoney />,
      subHed: "Affordable Courses",
      preGraph:
        "Our affordable courses aim to make quality education accessible to all, empowering diverse learners to achieve their goals.",
    },
    {
      icon: <FaReact />,
      subHed: "Efficient & Flexible",
      preGraph:
        "Designed for flexibility, our programs cater to diverse schedules, helping students balance academics with other commitments.",
    },
  ];

  return (
    <div className="w-full bg-white text-gray-800 flex justify-center py-8">
      <div className=" w-[98%]">
        <div className="flex flex-col lg:flex-row gap-12 items-center justify-between">
          {/* Left Section */}
          <div className="lg:w-1/2">
            <h1 className="md:text-3xl  text-[16px] font-semibold mb-4">
              Explore the Best Features at{" "}
              <span className={`${uni.text} text-orange-500`}>Parul University</span>
            </h1>
            <p className="text-gray-600 md:text-sm text-[12px] leading-relaxed mb-6">
              Quality education made accessible. At Parul University, we ensure students from diverse backgrounds can pursue their academic aspirations without financial constraints.
            </p>
            <div className="space-y-6">
              {features.map((data, index) => (
                <div className="flex gap-4 items-start" key={index}>
                  <div className="flex-shrink-0 w-14 h-14 flex items-center justify-center bg-orange-100 text-orange-500 rounded-full">
                    <div className="text-2xl">{data.icon}</div>
                  </div>
                  <div>
                    <h4 className="font-semibold md:text-lg text-[16px] mb-1">{data.subHed}</h4>
                    <p className="md:text-sm text-gray-600 text-[12px]">{data.preGraph}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Right Section */}
          <div className="lg:w-1/2 relative w-full">
            <div className="w-full h-32 md:h-96 overflow-hidden relative">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className={`absolute top-0 left-0 w-full h-full object-cover transition-transform duration-700 ease-in-out ${
                    currentSlide === index + 1 ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
                  }`}
                  style={{ borderRadius: "12px" }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurFeatures;
