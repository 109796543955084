import React from "react";

const Timeline = () => {
  const timelineData = [
    {
      date: "2009",
      title: "Parul University Established",
      description: "Parul University was established to provide quality education in various fields.",
    },
    {
      date: "2010",
      title: "First Batch of Students",
      description: "The first batch of students enrolled in various programs, marking the start of academic success.",
    },
    {
      date: "2015",
      title: "Accredited by NAAC",
      description: "The university was accredited by NAAC, marking its quality standards.",
    },
    {
      date: "2018",
      title: "International Collaborations",
      description: "The university started collaborations with international universities for student exchange programs.",
    },
    {
      date: "2020",
      title: "Launch of Online Programs",
      description: "Amidst the pandemic, Parul University launched various online programs to continue providing education.",
    },
    {
      date: "2024",
      title: "Expansion and New Programs",
      description: "The university expanded its infrastructure and launched new programs in emerging fields.",
    },
  ];

  return (
    <div className="w-full md:py-20 py-4 bg-white">
      <div className="w-[98%] mx-auto px-4">
        <h2 className="text-center text-[16px] md:text-3xl font-semibold md:mb-10 mb-6">
          Parul University Timeline
        </h2>
        <div className="relative">
          {/* Horizontal Line */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-full h-[2px] bg-gray-300"></div>
          </div>

          {/* Timeline Data */}
          <div className="flex flex-wrap justify-between relative z-10 md:space-x-4">
            {timelineData.map((item, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-center space-y-4 w-full md:w-auto mb-6 md:mb-0"
              >
                {/* Circle indicating the point on the timeline */}
                <div className="w-8 h-8 bg-[#ff7350] text-white rounded-full flex items-center justify-center shadow-lg">
                  <span className="font-semibold text-[10px] md:text-sm">
                    {item.date}
                  </span>
                </div>

                {/* Description */}
                <div
                  className="w-full max-w-[90%] md:max-w-[11rem] px-4 py-2 bg-white border-2 border-gray-300 rounded-lg shadow-lg mt-2"
                  style={{ zIndex: 2 }}
                >
                  <h3 className="font-bold text-[12px] md:text-lg text-[#333]">
                    {item.title}
                  </h3>
                  <p className="text-gray-600 mt-2 text-[10px] md:text-sm">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
