import React from "react";

function Scholarships({ uni }) {
  const backenddata = [
    { Header: "Scholarship Type", accessor: "College-specific" },
    { Header: "Organisation", accessor: uni.university },
    { Header: "Application Deadline", accessor: uni.applicationDeadline },
    { Header: "No. of Scholarships", accessor: uni.noScholarships },
    { Header: "Amount", accessor: uni.amount },
    { Header: "International Students", accessor: uni.internationalStudents },
    {
      Header: "Scholarship Link",
      accessor: uni.scholarshipLink,
      Cell: ({ value }) => (
        <a
          href={value}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline hover:text-blue-700"
        >
          Apply Now
        </a>
      ),
    },
  ];

  return (
    <div className="w-full py-8 px-4 lg:px-0 flex justify-center bg-white">
      <div className="w-[100%] md:[98%] px-4">
        {/* Heading */}
        <h2 className="text-[16px] md:text-2xl font-bold text-gray-800 mb-4 md:text-center">
          Scholarships for Students on{" "}
          <span
            className={`text-[${uni.textColor}]`}
          >
            the Basis of Marks/CGPA
          </span>
        </h2>

        {/* Table */}
        <div className="overflow-x-auto border border-gray-300 rounded-lg shadow-lg">
          <table className="w-full text-sm text-left text-gray-800 bg-gray-50">
            <tbody>
              {backenddata.map((mapdata, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0
                      ? "bg-white"
                      : "bg-gray-100"
                  } hover:bg-blue-50 transition-all duration-300`}
                >
                  {/* Header */}
                  <td className="px-4 py-3 font-semibold text-gray-600">
                    {mapdata.Header}
                  </td>
                  {/* Value */}
                  <td className="px-4 py-3 text-gray-700">
                    {mapdata.Cell ? (
                      mapdata.Cell({ value: mapdata.accessor })
                    ) : (
                      mapdata.accessor
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Scholarships;
