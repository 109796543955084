
import React from "react";
import campusImage1 from "./pexels-pixabay-256490.jpg";
import campusImage2 from "./pexels-pixabay-256490.jpg";
import campusImage3 from "./pexels-pixabay-256490.jpg";
import campusImage4 from "./pexels-pixabay-256490.jpg";
import campusImage5 from "./pexels-pixabay-256490.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

const ExploreCampus = () => {


  return (
    <div className="w-full md:py-14 py-8 bg-gradient-to-b from-gray-100 via-white to-gray-50">
      <div className="max-w-[85%] mx-auto flex flex-col lg:flex-row items-center gap-10">
        {/* Left Content */}
        <div className="lg:w-[40%] text-center lg:text-left md:space-y-6 space-y-2">
          <h2 className="md:text-[32px] text-[16px] font-extrabold text-gray-800 leading-tight">
            Explore Our <span className="text-blue-600">Campus</span>
          </h2>
          <p className="text-gray-600 md:text-[16px] text-[12px] leading-relaxed">
            Discover a vibrant environment designed for holistic learning,
            creativity, and innovation. Our campus offers cutting-edge
            facilities, including modern labs, libraries, and recreational
            spaces.
          </p>
          <ul className="text-gray-800 md:text-[16px] text-[12px] text-start font-semibold space-y-2">
            <li>✅ Modern Classrooms</li>
            <li>✅ Advanced Labs</li>
            <li>✅ Recreational Facilities</li>
            <li>✅ Green Campus</li>
          </ul>
          

          {/* <button className="mt-4 py-3 px-8 bg-[#FF0000] text-white font-bold rounded-lg shadow-md hover:bg-[#002147] transition duration-300">
            Learn More
          </button> */}
        </div>

        {/* Right Content: Interactive Image Gallery */}
        <div className="lg:w-[60%] grid grid-cols-3 gap-4">
          <div className="relative col-span-2 row-span-2 overflow-hidden rounded-lg shadow-lg md:h-[20rem] h-[12rem]">
            <img
              src={campusImage1}
              alt="Campus 1"
              className="w-full h-full object-cover transition-transform duration-500 hover:scale-110"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-end p-4">
              <p className="text-white font-semibold">Main Campus View</p>
            </div>
          </div>
          <div className="relative overflow-hidden rounded-lg shadow-lg h-[5.5rem]  md:h-[9rem]">
            <img
              src={campusImage2}
              alt="Campus 2"
              className="w-full h-full object-cover transition-transform duration-500 hover:scale-110"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-end p-4">
              <p className="text-white font-semibold">Modern Labs</p>
            </div>
          </div>
          <div className="relative overflow-hidden rounded-lg shadow-lg  h-[5.5rem]  md:h-[9.5rem]">
            <img
              src={campusImage3}
              alt="Campus 3"
              className="w-full h-full object-cover transition-transform duration-500 hover:scale-110"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-end p-4">
              <p className="text-white font-semibold">Recreational Spaces</p>
            </div>
          </div>

          {/* Slider Section Below */}
          <div className="col-span-3 mt-4">
            <Swiper
              slidesPerView={5}
              spaceBetween={20}
              navigation={true}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              modules={[Navigation, Autoplay]}
              breakpoints={{
                // when window width is >= 320px (mobile)
                320: {
                  slidesPerView: 3,
                },
                // when window width is >= 768px (tablet and desktop)
                768: {
                  slidesPerView: 5,
                },
              }}
              className="mySwiper"
            >

              {[campusImage4, campusImage5, campusImage2,campusImage5,campusImage5,campusImage5,campusImage5].map((image, index) => (
                <SwiperSlide key={index}>
                  <div className="overflow-hidden rounded-lg shadow-lg h-[6rem]">
                    <img
                      src={image}
                      alt={`Campus ${index + 1}`}
                      className="w-full h-full object-cover transition-transform duration-500 hover:scale-110"
                    />
                    <div className="p-2 bg-white text-center">
                      <p className="text-gray-800 font-semibold">Campus Spot {index + 1}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreCampus;

// import React from "react";
// import campusImage1 from "./pexels-pixabay-256490.jpg";
// import campusImage2 from "./pexels-pixabay-256490.jpg";
// import campusImage3 from "./pexels-pixabay-256490.jpg";
// import campusImage4 from "./pexels-pixabay-256490.jpg";

// const ExploreCampus = () => {
//   return (
//     <div className="w-full py-14 bg-gradient-to-b from-gray-100 via-white to-gray-50">
//       <div className="max-w-[85%] mx-auto flex flex-col lg:flex-row items-center gap-10">
//         {/* Left Content */}
//         <div className="lg:w-[45%] text-center lg:text-left space-y-6">
//           <h2 className="text-[32px] font-extrabold text-gray-800 leading-tight">
//             Explore Our <span className="text-[#FF0000]">Campus</span>
//           </h2>
//           <p className="text-gray-600 text-[16px] leading-relaxed">
//             Discover a vibrant environment designed for holistic learning,
//             creativity, and innovation. Our campus offers cutting-edge facilities,
//             including modern labs, libraries, and recreational spaces.
//           </p>
//           <button className="mt-4 py-3 px-8 bg-[#FF0000] text-white font-bold rounded-lg shadow-md hover:bg-[#002147] transition duration-300">
//             Learn More
//           </button>
//         </div>

//         {/* Right Content: Interactive Image Gallery */}
//         <div className="lg:w-[55%] grid grid-cols-3 gap-4">
//           <div className="relative col-span-2 row-span-2 overflow-hidden rounded-lg shadow-lg">
//             <img
//               src={campusImage1}
//               alt="Campus 1"
//               className="w-full h-full object-cover transition-transform duration-500 hover:scale-110"
//             />
//             <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-end p-4">
//               <p className="text-white font-semibold">Main Campus View</p>
//             </div>
//           </div>
//           <div className="relative overflow-hidden rounded-lg shadow-lg">
//             <img
//               src={campusImage2}
//               alt="Campus 2"
//               className="w-full h-full object-cover transition-transform duration-500 hover:scale-110"
//             />
//             <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-end p-4">
//               <p className="text-white font-semibold">Modern Labs</p>
//             </div>
//           </div>
//           <div className="relative overflow-hidden rounded-lg shadow-lg">
//             <img
//               src={campusImage3}
//               alt="Campus 3"
//               className="w-full h-full object-cover transition-transform duration-500 hover:scale-110"
//             />
//             <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-end p-4">
//               <p className="text-white font-semibold">Recreational Spaces</p>
//             </div>
//           </div>
//           <div className="relative overflow-hidden rounded-lg shadow-lg">
//             <img
//               src={campusImage4}
//               alt="Campus 4"
//               className="w-full h-full object-cover transition-transform duration-500 hover:scale-110"
//             />
//             <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-end p-4">
//               <p className="text-white font-semibold">Library</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ExploreCampus;
