import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Testimonals({ uni }) {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 4000,
    slidesToShow: 4, // Show 4 slides on desktop
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024, // For tablets
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For small tablets and large phones
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // For mobile devices
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full md:py-6 py-2 bg-white flex justify-center">
      <div className="w-[98%] ">
        {/* Heading */}
        <h2 className="text-center text-[16px] md:text-xl font-bold md:mb-4 mb-2">
          Our Placed <span className="text-blue-600">Students</span>
        </h2>

        {/* Slider */}
        <Slider {...settings}>
          {uni &&
            uni.ourPlacedStudent.map((item, ind) => (
              <div key={ind} className="px-2">
                <div className="bg-blue-50 hover:bg-white hover:shadow-lg transition-shadow duration-300 rounded-lg p-3">
                  {/* Student Image */}
                  <img
                    src={item.perImg}
                    alt={item.Name}
                    className="w-20 h-20 md:w-24 md:h-24 rounded-full mx-auto object-cover mb-2"
                  />

                  {/* Name and Work */}
                  <h3 className="text-center md:text-sm text-[12px] font-semibold text-gray-800">
                    {item.Name}
                  </h3>
                  <p className="text-center md:text-xs text-[12px] text-gray-600 mt-1">
                    {item.work}
                  </p>

                  {/* Company Logo */}
                  <img
                    src={item.Topimg}
                    alt={`${item.Name} Company`}
                    className="md:w-14 md:h-14 w-4 h-4 mx-auto mt-3"
                  />

                  {/* Review */}
                  <p className="text-center md:text-xs  text-[12px] text-gray-700 mt-2 leading-snug h-12 overflow-hidden">
                    {item.Review}
                  </p>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
}

export default Testimonals;

