import React from "react";
import UniversityPage from "./screen/home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoCall } from "react-icons/io5";

import { FaArrowRight, FaCalendarAlt } from "react-icons/fa";
import im1 from "./screen/home/courses/image/Courses/1.png";
// import im2 from "./screen/home/courses/image/Courses/2.png";
// import im3 from "./screen/home/courses/image/Courses/3.png";
import im4 from "./screen/home/courses/image/Courses/7.png";
import im5 from "./screen/home/courses/image/Courses/8.png";
import im6 from "./screen/home/courses/image/Courses/9.png";
import im7 from "./screen/home/courses/image/Courses/10.png";
import im8 from "./screen/home/courses/image/Courses/11.png";

import webdev from "./images/parulUniversity/event/webdev.jpg";
import analyis from "./images/parulUniversity/event/analyis.jpg";
import entrepreneur from "./images/parulUniversity/event/entrepreneur.jpg";

import Navbar from "./screen/home/navbar";
import Enquriyform from "./common/enquiryform/page";
import Footer from "./screen/home/Footer/Footer";
import Disclaimer from "./disclaimer";

import mahesh from "./images/parulUniversity/placedStudent/mahesh.webp";
import malav from "./images/parulUniversity/placedStudent/dshf.webp";
import gunjanpandya from "./images/parulUniversity/placedStudent/677721gunjan.jpg";
import jaivaidya from "./images/parulUniversity/placedStudent/499389jay.jpg";
// import hemalikhatri from "./images/parulUniversity/placedStudent/971745Hemali Khatri-min.jpg";
import parulh1 from "./images/parulUniversity/hiringPartner/2713logo 2.png";
import parulh2 from "./images/parulUniversity/hiringPartner/3891Elmex_Logo.png";
import parulh3 from "./images/parulUniversity/hiringPartner/5559Logo 1.png";
import parulh4 from "./images/parulUniversity/hiringPartner/6361r1.png";
import parulh5 from "./images/parulUniversity/hiringPartner/7463logo 8.jpg";
import parulh6 from "./images/parulUniversity/hiringPartner/8195Logo-18.png";
import parulh7 from "./images/parulUniversity/hiringPartner/8831r7.png";
import parulh8 from "./images/parulUniversity/hiringPartner/9517Page-72-Image-544.png";
import parulh9 from "./images/parulUniversity/hiringPartner/9665BYJUS.jpg";
import BlogPost1 from "./screen/blog/blog1";
import BlogPost2 from "./screen/blog/blog2";
import PrivacyPolicy from "./screen/privacyPolicy";
import parul1 from "./images/parulUniversity/parul.webp";
import parul2 from "./images/parulUniversity/parul2.jpg";
import parul3 from "./images/parulUniversity/parulgate.jpg";
const App = () => {
  
  const uni = {
    id: "parul-university",
    Color: "bg-[#FFEFEF]",
    text: "[#FAC51C]",
    university: "Parul University",
    image: [parul1, parul3, parul2],
    courses: [
      {
        name: "B.Tech",
        fee: "₹ 1,00,000/year",
        details: ["4 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          "Computer Science",
          "Electrical Engineering",
          "Mechanical Engineering",
          "Civil Engineering",
          "Electronics and Communication",
        ],
        location: "Vadodara, Gujarat",
        approval: true,
        image: im5, // image source for B.Tech
        description: "Description for B.Tech course goes here.",
      },
      {
        name: "M.Tech",
        fee: "₹ 1,20,000/year",
        details: ["2 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship: "According to entrance exam results.",
        specialisation: [
          "Computer Science",
          "Electrical Engineering",
          "Mechanical Engineering",
          "Civil Engineering",
          "Electronics and Communication",
        ],
        location: "Vadodara, Gujarat",
        approval: true,
        image: im6, // image source for M.Tech
        description: "Description for M.Tech course goes here.",
      },
      {
        name: "BCA",
        fee: "₹ 80,000/year",
        details: ["3 Year", "On-campus", "Full Time"],
        rating: 4.8,
        scholarship: "According to merit and need-based.",
        specialisation: [
          "Computer Applications",
          "Software Development",
          "Web Development",
          "Networking",
          "Database Management",
        ],
        location: "Vadodara, Gujarat",
        approval: true,
        image: im5, // image source for BCA
        description: "Description for BCA course goes here.",
      },
      {
        name: "MCA",
        fee: "₹ 1,00,000/year",
        details: ["3 Year", "On-campus", "Full Time"],
        rating: 4.9,
        scholarship:
          "According to entrance exam results and academic performance.",
        specialisation: [
          "Software Engineering",
          "Data Science",
          "Information Security",
          "Mobile Applications",
          "Cloud Computing",
        ],
        location: "Vadodara, Gujarat",
        approval: true,
        image: im6, // image source for MCA
        description: "Description for MCA course goes here.",
      },
      {
        name: "M.Com",
        fee: "₹ 55,000/year",
        details: ["2 Year", "On-campus", "Full Time"],
        rating: 4.8,
        scholarship: "Merit-based scholarships available.",
        specialisation: [
          "Advanced Accounting",
          "Business Statistics",
          "Financial Management",
          "Business Law",
          "Economics",
        ],
        location: "Vadodara, Gujarat",
        approval: true,
        image: im4, // image source for M.Com
        description: "Description for M.Com course goes here.",
      },
      {
        name: "B.Com",
        fee: "₹ 50,000/year",
        details: ["3 Year", "On-campus", "Full Time"],
        rating: 4.7,
        scholarship: "Merit-based scholarships available.",
        specialisation: [
          "Accounting",
          "Finance",
          "Taxation",
          "Auditing",
          "Economics",
        ],
        location: "Vadodara, Gujarat",
        approval: true,
        image: im8, // image source for B.Com
        description: "Description for B.Com course goes here.",
      },
    ],
    event: [
      {
        img: analyis,
        data: "12",
        month: "April, 2023",
        hed: "Advanced Data Analysis Workshop",
        pre: "Unlock insights from your data using advanced analytical techniques",
        time: "9:00 am - 5:00 pm",
        country: "United States",
        alt: "Data Analysis Image",
        arrow: <FaArrowRight />,
      },
      {
        img: im8,
        data: "25",
        month: "May, 2023",
        hed: "Digital Marketing Strategies Conference",
        pre: "Discover the latest trends and strategies in digital marketing",
        time: "10:00 am - 6:00 pm",
        country: "Canada",
        alt: "Digital Marketing Image",
        arrow: <FaArrowRight />,
      },
      {
        img: im7,
        data: "8",
        month: "June, 2023",
        hed: "Blockchain Technology Summit",
        pre: "Explore the applications and potential of blockchain technology",
        time: "1:00 pm - 7:00 pm",
        country: "Germany",
        alt: "Blockchain Image",
        arrow: <FaArrowRight />,
      },
      {
        img: im1,
        data: "20",
        month: "July, 2023",
        hed: "Artificial Intelligence Workshop",
        pre: "Learn about the fundamentals and applications of AI",
        time: "2:00 pm - 5:00 pm",
        country: "Australia",
        alt: "AI Workshop Image",
        arrow: <FaArrowRight />,
      },
      {
        img: entrepreneur,
        data: "14",
        month: "August, 2023",
        hed: "Entrepreneurship Bootcamp",
        pre: "Gain valuable insights and skills to start your own business",
        time: "9:30 am - 4:30 pm",
        country: "India",
        alt: "Entrepreneurship Image",
        arrow: <FaArrowRight />,
      },
      {
        img: webdev,
        data: "3",
        month: "September, 2023",
        hed: "Web Development Crash Course",
        pre: "Master the essentials of web development in just one day",
        time: "10:00 am - 3:00 pm",
        country: "France",
        alt: "Web Development Image",
        arrow: <FaArrowRight />,
      },
    ],
    ourPlacedStudent: [
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "Parul University provided me with excellent technical education and ample career opportunities, leading to my successful placement in a reputable company.",
        perImg: gunjanpandya,
        Name: "Gunjan Pandya",
        post: "Data Engineer",
        work: "Facebook",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "Parul University equipped me with top-notch skills and connections, securing a prestigious placement for me.",
        perImg: mahesh,
        Name: "Mahesh Patel",
        post: "Manager",
        work: "Johnsen &  Johnsen",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "Parul University's supportive environment and strong industry ties paved the way for my successful placement.",
        perImg: jaivaidya,
        Name: "Jai Vaidya",
        work: "Amazon",
        post: "Sr. Cloud Technical Manager",
      },
      {
        Topimg:
          "http://htmldemo.zcubethemes.com/qeducato/img/testimonial/qt-icon.png",
        Review:
          "At Parul University, I received quality education and valuable guidance that led to my placement in a prominent company.",
        perImg: malav,
        Name: "Malav shah",
        work: "Deloitte",
        post: "Analyst",
      },
    ],
    applicationDeadline: "13 july",
    noScholarships: "11",
    amount: "variable",
    internationalStudents: "yes",
    scholarshipLink: "www.aapadhe.in",
    loanBNK:
      "Parul HAS COLLABORATED WITH STATE BANK OF INDIA TO FACILITATE ITS STUDENTS WHO ARE INTERESTED IN AVAILING EDUCATION LOAN.",
    email: "parul@offical.com",
    phone: "+918829908360",
    address: "Vadodara, Gujarat",
    hiringPartner: [
      parulh1,
      parulh2,
      parulh3,
      parulh4,
      parulh5,
      parulh6,
      parulh7,
      parulh8,
      parulh9,
      parulh4,
      parulh6,
      parulh8,
      parulh5,
      parulh3,
    ],
    studentEnroll: "125,300",
    registeredInstructor: "300",
    successRate: "100",
    successStory: [
      "https://www.youtube.com/embed/9ojF-cASF-Q",
      "https://www.youtube.com/embed/H2GTlRUwhuQ",
      "https://www.youtube.com/embed/HlYiW3_v9Rw",
      "https://www.youtube.com/embed/5WucQ2XTBQA",
      "https://www.youtube.com/embed/9ojF-cASF-Q",
      "https://www.youtube.com/embed/H2GTlRUwhuQ",
      "https://www.youtube.com/embed/HlYiW3_v9Rw",
      "https://www.youtube.com/embed/5WucQ2XTBQA",
    ],
    doubtSection: [
      {
        question: "Is Parul University good?",
        answer1:
          "It has featured among the top 10 engineering colleges in Uttarakhand. Over the past few years, Parul University has won the title of best placement college in Dehradun, due to its latest and innovative teaching-learning methodology. Several reputed companies visit the campus every year for recruiting Q-mates.",
        answer2: null,
      },
      {
        question: "Is Parul University degree valid?",
        answer1:
          "Yes, the degree offered by Parul University is valid. Parul University is recognized by the University Grants Commission, which is a statutory body of the Government of India.",
        answer2: null,
      },
      {
        question: "How old is Parul University?",
        answer1:
          "History. Parul University Roorkee was founded in 2017 by industrialist Shri Shyam Sunder Goyal. The core programs initially offered by the college were in the disciplines of Engineering and Management. In 2018, the university started programs in Graduate Studies.",
        answer2: null,
      },
      {
        question: "Is Parul University UGC approved?",
        answer1:
          "Parul University is a private university in Roorkee, Uttarakhand, India. It was established in 2008 and is approved by the University Grants Commission, which is a statutory body of the Government of India. Parul University has both government recognition and professional accreditation.",
        answer2: null,
      },
      {
        question: "Is Parul University good for placement?",
        answer1:
          "Placements: Yes, Parul University arranges for placement, and more than 100 students got placed this year alone. A total of 900 students received placement offers from Parul University in various companies and MNCs.",
        answer2: null,
      },
    ],
    blogNews: [
      {
        img: webdev,
        data: "25",
        icon: <FaCalendarAlt />,
        month: "25th February 2024",
        hed: "Job Fair Event.",
        pre: "TechPro Solutions Pvt Ltd Job Fair Event for Students of Parul University",
        txt: "Read More",
        country: "United States",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
      {
        img: analyis,
        data: "12",
        icon: <FaCalendarAlt />,
        month: "12th March 2024",
        hed: "Recruitment Event.",
        pre: "Globex Corporation Recruitment Event for Students of Parul University",
        txt: "Read More",
        country: "Australia",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
      {
        img: entrepreneur,
        data: "7",
        icon: <FaCalendarAlt />,
        month: "7th April 2024",
        hed: "Internship Drive for Students.",
        pre: "Infinity Solutions Internship Drive for Students of Parul University",
        txt: "Read More",
        country: "Canada",
        alt: "Student Image",
        arrow: <FaArrowRight />,
      },
    ],
  };
  return (
    <div className="overflow-hidden">
      <Router>
        {/* <TopBar></TopBar> */}
        <Navbar uni={uni}></Navbar>
        <Routes>
          {/* Route for the home page */}
          <Route path="/" element={<UniversityPage uni={uni} />} />
          <Route path="/courses" element={<UniversityPage uni={uni} />} />
          <Route path="/apply-now" element={<UniversityPage uni={uni} />} />
          <Route path="/scholarship" element={<UniversityPage uni={uni} />} />
          {/* Route for the enquiry form */}
          <Route path="/enquiryform" element={<Enquriyform />} />
          <Route path="/blogpost2" element={<BlogPost2 />} />
          <Route path="/blogpost1" element={<BlogPost1 />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer uni={uni} />
        {/* <Disclaimer /> */}

        <div className="fixed bottom-0 md:text-[30px] text-[15px] right-0 gap-3 md:px-8 px-2 py-6 z-[9999]">
          <div className="p-3 bg-gray-300 mb-3 rounded-full">
            <a href="tel:+918829908360">
              <IoCall className="text-blue-500" />
            </a>
          </div>
          <div className="p-3 bg-gray-300 mb-2 rounded-full">
            <a href="https://wa.me/+918829908360">
              <IoLogoWhatsapp className="text-green-500" />
            </a>
          </div>
        </div>
      </Router>
    </div>
  );
};

export default App;
