import React from "react";
import { FaChalkboardTeacher, FaBuilding, FaHandshake } from "react-icons/fa";

const Why = () => {
  const reasons = [
    {
      icon: <FaChalkboardTeacher />,
      title: "Expert Faculty",
      description: "Learn from industry leaders and highly qualified professors.",
      color: "bg-purple-500",
    },
    {
      icon: <FaBuilding />,
      title: "State-of-the-Art Campus",
      description: "Enjoy world-class infrastructure and modern facilities.",
      color: "bg-teal-500",
    },
    {
      icon: <FaHandshake />,
      title: "Career Opportunities",
      description:
        "Benefit from strong industry connections and placement programs.",
      color: "bg-yellow-500",
    },
  ];

  const highlights = [
    {
      title: "Scholarships for Meritorious Students",
      description:
        "Over 500 scholarships awarded every year to help you achieve your dreams.",
    },
    {
      title: "Global Exposure Programs",
      description:
        "Opportunities to participate in international exchange programs and projects.",
    },
    {
      title: "Cutting-Edge Research Facilities",
      description:
        "Access to state-of-the-art labs and resources to foster innovation and discovery.",
    },
  ];

  return (
    <div className="w-full bg-gray-50 md:py-12 py-4 px-6 flex flex-col items-center">
      <h2 className="text-gray-800 md:text-3xl text-[16px ] font-bold md:mb-8 md:mb-2">Why Choose Us</h2>
      <p className="text-gray-600 mb-12 text-center max-w-2xl">
        Discover the reasons why <span className="text-blue-500">Parul University</span> is the best choice for your education.
      </p>

      {/* Main Layout */}
      <div className="relative w-full max-w-7xl flex flex-col lg:flex-row items-start">
        {/* Left Timeline Container */}
        <div className="relative w-full lg:w-1/2">
          {/* Colorful Vertical Line */}
          <div className="absolute top-0 md:left-[90%] left-[100%] transform -translate-x-1/2 h-full w-2 bg-gradient-to-b from-purple-500 via-teal-500 to-yellow-500"></div>

          {/* Reasons */}
          {reasons.map((reason, index) => (
            <div
              key={index}
              className={`flex items-center mb-10 ${
                index % 2 === 0
                  ? "justify-start flex-row"
                  : "justify-end flex-row-reverse"
              }`}
            >
              {/* Circular Connector */}
              <div
                className={`h-6 w-6  rounded-full z-10 border-4 border-white shadow-lg ${reason.color}`}
              ></div>

              {/* Content Box */}
              <div
                className={`bg-white shadow-lg md:p-6  p-4 rounded-xl md:max-w-md ${
                  index % 2 === 0 ? "ml-8" : "mr-8"
                }`}
              >
                <div
                  className={`md:w-12 md:h-12 w-8 h-8  flex justify-center items-center rounded-full text-white text-xl mb-4 ${reason.color}`}
                >
                  {reason.icon}
                </div>
                <h3 className="md:text-lg text-[12px] font-semibold text-gray-800 mb-2">
                  {reason.title}
                </h3>
                <p className="md:text-sm text-[12px] text-gray-600">{reason.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Right Content */}
        <div className="w-full lg:w-1/2 lg:pl-12 mt-10 lg:mt-0">
          <h3 className="md:text-2xl text-[16px] font-semibold text-gray-800 mb-6">
            Highlights of Parul University
          </h3>
          <div className="space-y-6">
            {highlights.map((highlight, index) => (
              <div
                key={index}
                className="bg-white shadow-lg p-6 rounded-lg flex flex-col"
              >
                <h4 className="md:text-lg text-[12px] font-semibold text-blue-500 mb-2">
                  {highlight.title}
                </h4>
                <p className="md:text-sm  text-[12px]  text-gray-600">{highlight.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why;
