import React, { useState, useEffect } from "react";
import { TiTick } from "react-icons/ti";
import { Link } from "react-router-dom";

const courseIcons = {
  BBA: "https://cdn-icons-png.flaticon.com/512/3209/3209963.png",
  BCA: "https://cdn-icons-png.flaticon.com/512/1055/1055687.png",
  MBA: "https://cdn-icons-png.flaticon.com/512/3135/3135755.png",
  "B.Tech": "https://cdn-icons-png.flaticon.com/512/3135/3135757.png",
};

const Courses = ({ uni }) => {
  const [coursesdata, setCoursesData] = useState(null);
  const [datanum, setDatanum] = useState(8);

  useEffect(() => {
    if (uni && uni.courses) {
      setCoursesData(uni.courses);
    }
  }, [uni]);

  const increNum = () => {
    setDatanum(datanum + 8);
  };

  return (
    <div className="w-full md:py-12 py-6 bg-white flex justify-center">
      <div className="w-[98%]">
        {/* Heading */}
        <div className="text-center mb-4">
          <h1 className="text-[16px] md:text-[28px] font-bold text-gray-800">
            Explore Parul{" "}
            <span className="text-blue-600">University Courses</span>
          </h1>
        </div>

        {/* Courses Grid */}
        <div className="grid grid-cols-3 sm:grid-cols-2 lg:grid-cols-6 md:gap-6 gap-1 mt-4">
          {coursesdata &&
            coursesdata.slice(0, datanum).map((course, index) => (
              <div
                key={index}
                className="bg-white rounded-lg border border-gray-200 shadow-md overflow-hidden transition-transform hover:scale-105 hover:shadow-lg"
              >
                {/* Icon */}
                <div className="h-16 flex justify-center items-center bg-gradient-to-r from-gray-100 via-white to-gray-50 hidden md:flex">
                  <div className="w-12 h-12 bg-white rounded-full flex justify-center items-center shadow-sm">
                    <img
                      src={courseIcons[course.name] || "https://cdn-icons-png.flaticon.com/512/3135/3135756.png"}
                      alt={course.name}
                      className="w-8 h-8"
                    />
                  </div>
                </div>

                {/* Details */}
                <div className="p-1 md:p-6 space-y-2">
                  <h2 className="text-[14px] font-bold text-gray-800 truncate">
                    {course.name}
                  </h2>
                  <div className="space-y-1 text-[12px] text-gray-600">
                    <p>
                      <span className="font-semibold">Fees:</span> {course.fee}
                    </p>
                    <p>
                      <span className="font-semibold">Duration:</span>{" "}
                      {course.details[0]}
                    </p>
                    <p>
                      <span className="font-semibold">Location:</span>{" "}
                      {course.location}
                    </p>
                    {course.approval && (
                      <p className="flex items-center text-green-600">
                        <TiTick />
                        <span className="ml-1">Approved by UGC AICTE</span>
                      </p>
                    )}
                  </div>

                  {/* Apply Now Button */}
                  <div className="mt-2">
                    <Link to="/enquiryform">
                      <button
                        className="w-full py-1 text-white text-[12px] font-bold rounded-md transition duration-300"
                        style={{
                          backgroundColor: "#002147", // Example theme color
                        }}
                      >
                        Apply Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>

        {/* Load More Button */}
        {coursesdata && datanum < coursesdata.length && (
          <div className="text-center mt-8">
            <button
              onClick={increNum}
              className="py-2 px-6 bg-[#FF0000] text-white font-bold rounded-md hover:bg-[#002147] transition duration-300"
            >
              View More Courses
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Courses;
