import React from "react";
import Container from "../container/Container";
import InfiAnimation from "../../../extra/infiAnimation/index";

const HiringPartner = ({ uni }) => {
  return (
    <div className="bg-white md:mb-[5rem] mb-3">
      <h1 className="text-center md:mb-4 mb-1 text-gray-800 pt-6 leading-4 md:text-[25px] text-[16px] font-semibold capitalize">
       Hiring Partners 
      </h1>
      {/* <h1 className="md:text-[40px] text-[40px] text-center text-black font-bold">
        {uni ? uni.university : "Internal Server Error"}
      </h1> */}
      <InfiAnimation uni={uni} />
    </div>
  );
};

export default HiringPartner;
