import React, { useState } from "react";

function UniFaq({ uni, alwaysOpenIndex = 0 }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    // Prevent the always-open question from being toggled
    if (index !== alwaysOpenIndex) {
      setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    }
  };

  return (
    <section className="bg-white md:py-16 py-2">
      <div className="max-w-4xl mx-auto px-6">
        <h2 className="text-[16px] md:text-4xl font-bold text-gray-800 text-center md:mb-8 mb-2">
          Let’s Clear Some Doubts
        </h2>
        <div className="space-y-4 text-[13px]">
          {uni?.doubtSection?.map((item, index) => (
            <div
              key={index}
              className={`border rounded-lg shadow-sm transition ${
                activeIndex === index || index === alwaysOpenIndex
                  ? "border-blue-500 bg-blue-50"
                  : "border-gray-300 bg-white"
              }`}
            >
              <div
                className="flex justify-between items-center px-6 py-4 cursor-pointer"
                onClick={() => toggleAccordion(index)}
              >
                <h3
                  className={`md:text-lg text-[12px] font-medium ${
                    activeIndex === index || index === alwaysOpenIndex
                      ? "text-blue-600"
                      : "text-gray-800"
                  }`}
                >
                  {item.question}
                </h3>
                <span
                  className={`md:text-2xl text-[13px] transition-transform transform ${
                    activeIndex === index || index === alwaysOpenIndex
                      ? "rotate-180 text-blue-500"
                      : ""
                  }`}
                >
                  {activeIndex === index || index === alwaysOpenIndex ? "−" : "+"}
                </span>
              </div>
              {(activeIndex === index || index === alwaysOpenIndex) && (
                <div className="px-6 md:py-4 py-2 text-gray-700">
                  <ul className="list-disc pl-6 space-y-2">
                    <li>{item.answer1}</li>
                    {item.answer2 && <li>{item.answer2}</li>}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default UniFaq;
