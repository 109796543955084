import React from "react";

function LoanFec({ uni }) {
  return (
    <div className="w-full py-8 bg-white flex justify-center">
      <div className="w-[100%] sm:w-[90%] lg:w-[80%] max-w-4xl px-6">
        {/* Heading */}
        <h2
          className={`text-[${uni.textColor}] md:text-[24px] text-[16px] font-bold border-b-2 border-gray-300 pb-2 mb-4`}
        >
          Education Loan Facility
        </h2>

        {/* Content */}
        <p className="text-gray-600 text-[12px] md:text-base leading-6 mb-4">
          {uni.loanBNK ||
            "We assist students in securing education loans to ensure uninterrupted studies. Below are the contact details for further guidance:"}
        </p>

        {/* Loan Details */}
        <ul className="bg-[#f9f9f9] p-4 rounded-lg shadow-md text-gray-700 space-y-3">
          <li className="font-bold text-gray-800 md:text-lg text-[16px]">Details:</li>
          <li className="flex items-center space-x-2">
            <span className="font-medium md:text-lg text-[13px]">Contact Person:</span>
            <span className="md:text-lg text-[13px]">Education Loan Coordinator</span>
          </li>
          <li className="flex items-center space-x-2">
            <span className="font-medium md:text-lg text-[13px]">Phone:</span>
            <span className="md:text-lg text-[12px]">{uni.phone || "Not Available"}</span>
          </li>
          <li className="flex items-center space-x-2">
            <span className="font-medium md:text-lg text-[13px]">Email:</span>
            <span className="md:text-lg text-[12px]">
              {uni.email ? (
                <a
                  href={`mailto:${uni.email}`}
                  className="text-blue-500 hover:underline md:text-lg text-[13px]"
                >
                  {uni.email}
                </a>
              ) : (
                "Not Available"
              )}
            </span>
          </li>
        </ul>

        {/* Additional Links */}
        <div className="mt-6">
          <a
            href={uni.loanLink || "#"}
            className=" md:text-lg text-[13px] inline-block bg-blue-500 text-white  font-semibold py-2 px-4 rounded-lg hover:bg-blue-600 transition"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More about Loan Facility
          </a>
        </div>
      </div>
    </div>
  );
}

export default LoanFec;
