import React from "react";
import { FaUserEdit, FaClipboardCheck, FaGraduationCap } from "react-icons/fa";

const Admission = () => {
  return (
    <div className="w-full bg-white py-12 px-6">
      <div className="max-w-[85%] mx-auto flex flex-col lg:flex-row items-center lg:items-start gap-10">
        {/* Left: Steps */}
        <div className="lg:w-[50%] space-y-6">
          <h2 className="text-gray-800 text-[16px] lg:text-3xl font-bold mb-6 text-center lg:text-left">
            Admission Process
          </h2>
          <p className="text-gray-600 text-[12px] lg:text-base mb-8 text-center lg:text-left">
            Follow these simple steps to get enrolled at{" "}
            <span className="text-blue-500">Parul University</span>.
          </p>
          <div className="relative w-full">
            {/* Step 1 */}
            <div className="flex items-center mb-6">
              <div className="w-8 h-8 lg:w-12 lg:h-12 bg-blue-500 text-white rounded-full flex justify-center items-center text-sm lg:text-xl">
                <FaUserEdit />
              </div>
              <div className="ml-4">
                <h3 className="text-sm lg:text-xl font-semibold text-gray-800">
                  Step 1: Apply Online
                </h3>
                <p className="text-xs lg:text-sm text-gray-600">
                  Fill out the online application form available on the official
                  website.
                </p>
              </div>
            </div>

            {/* Line */}
            <div className="h-6 w-0.5 bg-gray-300 ml-6"></div>

            {/* Step 2 */}
            <div className="flex items-center mb-6">
              <div className="w-8 h-8 lg:w-12 lg:h-12 bg-orange-500 text-white rounded-full flex justify-center items-center text-sm lg:text-xl">
                <FaClipboardCheck />
              </div>
              <div className="ml-4">
                <h3 className="text-sm lg:text-xl font-semibold text-gray-800">
                  Step 2: Submit Documents
                </h3>
                <p className="text-xs lg:text-sm text-gray-600">
                  Provide the necessary academic and identification documents
                  for verification.
                </p>
              </div>
            </div>

            {/* Line */}
            <div className="h-6 w-0.5 bg-gray-300 ml-6"></div>

            {/* Step 3 */}
            <div className="flex items-center">
              <div className="w-8 h-8 lg:w-12 lg:h-12 bg-green-500 text-white rounded-full flex justify-center items-center text-sm lg:text-xl">
                <FaGraduationCap />
              </div>
              <div className="ml-4">
                <h3 className="text-sm lg:text-xl font-semibold text-gray-800">
                  Step 3: Get Enrolled
                </h3>
                <p className="text-xs lg:text-sm text-gray-600">
                  Complete the fee payment and start your journey with us.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Right: Form */}
        <div className="lg:w-[50%] bg-gray-50 p-6 shadow-md rounded-lg">
          <h3 className="text-gray-800 text-lg lg:text-2xl font-bold mb-4 text-center">
            Fill Out the Form
          </h3>
          <form className="space-y-4">
            <div>
              <label
                htmlFor="name"
                className="block text-xs lg:text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                placeholder="Enter your name"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-xs lg:text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label
                htmlFor="program"
                className="block text-xs lg:text-sm font-medium text-gray-700"
              >
                Program of Interest
              </label>
              <select
                id="program"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select a program</option>
                <option value="bba">BBA</option>
                <option value="bca">BCA</option>
                <option value="mba">MBA</option>
                <option value="btech">B.Tech</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-xs lg:text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                placeholder="Enter your phone number"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <button
              type="submit"
              className="w-full py-2 bg-blue-400 text-white font-semibold rounded-lg hover:bg-[#002147] transition duration-300"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Admission;
